import React from 'react';
import { OpenApp } from './OpenApp';

export const NoWalletDetected = () => (
  <div className="card-body">
    <div className="container">
      <div className="row justify-content-center">
        <button className="btn btn-warning btn-lg">
          <OpenApp
            href="https://metamask.io/download.html"
            mobile="https://metamask.app.link/dapp/snapcaster.xyz"
            blank="true"
          >
            Install Metamask
          </OpenApp>
        </button>
      </div>
    </div>
  </div>
);
