import React from 'react';

import { NetworkErrorMessage } from './NetworkErrorMessage';

export function ConnectWallet({ connectWallet }) {
  return (
    <button
      className="btn btn-warning btn-lg"
      type="button"
      onClick={connectWallet}
    >
      Connect Wallet
    </button>
  );
}
