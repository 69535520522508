import React from 'react';

// We'll use ethers to interact with the Ethereum network and our contract
import { ethers } from 'ethers';
import axios from 'axios';
// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import SnapCasterArtifact from '../contracts/SnapCaster.json';
import contractAddress from '../contracts/contract-address.json';

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
import { NoWalletDetected } from './NoWalletDetected';

import { Loading } from './Loading';
import { Transfer } from './Transfer';
import { TransactionErrorMessage } from './TransactionErrorMessage';
import { WaitingForTransactionMessage } from './WaitingForTransactionMessage';
import { NoTokensMessage } from './NoTokensMessage';
import { EmptyView } from './EmptyView';
import { InputButton } from './InputButton';

// This is the Hardhat Network id, you might change it in the hardhat.config.js.
// If you are using MetaMask, be sure to change the Network id to 1337.
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
const HARDHAT_NETWORK_ID = '31337';
const RINKEBY_NETWORK_ID = '4';
const MAINNET_NETWORK_ID = '1';

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

// This component is in charge of doing these things:
//   1. It connects to the user's wallet
//   2. Initializes ethers and the Token contract
//   3. Polls the user balance to keep it updated.
//   4. Transfers tokens by sending transactions
//   5. Renders the whole application
//
// Note that (3) and (4) are specific of this sample application, but they show
// you how to keep your Dapp and contract's state in sync,  and how to send a
// transaction.
export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      // The info of the token (i.e. It's Name and symbol)
      tokenData: undefined,
      // The user's address and balance
      selectedAddress: undefined,
      balance: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      image: undefined,
      uploadOk: undefined,
      status: undefined,
    };

    this.state = this.initialState;
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      const file = event.target.files[0];
      reader.onload = (e) => {
        this.setState({
          image: e.target.result,
          imageFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  notify = async (event) => {
    event.preventDefault();
    try {
      await this._snap.storyEvent();
      this.setState({ status: '🔔 Notified' });
    } catch {
      this.setState({ status: '🙃 Please retry' });
    }
  };

  delay = (n) => {
    return new Promise(function (resolve) {
      setTimeout(resolve, n * 1000);
    });
  };

  upload = async (event) => {
    event.preventDefault();
    const { image, imageFile, tokenId, hasSnap } = this.state;
    if (image === undefined) {
      return;
    }
    const signer = this._provider.getSigner(0);
    const ts = Date.now();
    const address = await signer.getAddress();
    const message = `SNAPCASTER;${ts};${tokenId}`;
    const signature = await signer.signMessage(message);

    const formData = new FormData();
    formData.append('snapimage', imageFile);
    formData.append('sign', signature);
    formData.append('message', message);
    formData.append('address', address);
    formData.append('tokenId', tokenId);

    try {
      this.setState({
        status: '⌛ Uploading...',
      });
      const ok = await axios.post('https://snapcaster.xyz/upload', formData);
      console.log({ published: true, ok });
      this.setState({
        uploadOk: true,
        hasSnap: true,
        status: '🙂 Published',
      });
    } catch (err) {
      console.log({ published: false, err });
      this.setState({ uploadOk: false, status: '🙃 Please retry' });
    }
  };

  _checkImage = async () => {
    const { tokenId, fetchingImage, hasSnap } = this.state;
    if (hasSnap !== undefined) {
      return;
    }
    if (fetchingImage) {
      return;
    }

    if (!tokenId) {
      return;
    }

    this.setState({
      fetchingImage: true,
      status: '⌛ Loading...',
    });
    const img = new Image();
    img.onload = () =>
      this.setState({ hasSnap: true, fetchingImage: false, status: '' });
    img.onerror = () =>
      this.setState({ hasSnap: false, fetchingImage: false, status: '' });

    img.src = `https://stories.snapcaster.xyz/${tokenId}`;
  };

  mint = async (event) => {
    event.preventDefault();
    await this._snap.mint();

    while (true) {
      const { selectedAddress } = this.state;
      const tokenIdRaw = await this._snap.tokenForUser(selectedAddress);
      const tokenIdInt = Number(tokenIdRaw.toString());
      if (tokenIdRaw === undefined || tokenIdInt === 0) {
        this.setState({ status: '⌛ Waiting for confirmation...' });
      } else {
        this.setState({ tokenId: tokenIdInt, status: '' });
        break;
      }
      await this.delay(2);
    }
  };

  render() {
    const noWallet = window.ethereum === undefined;
    const { image, tokenId, selectedAddress, hasSnap, status } = this.state;
    const disabled = tokenId === undefined || tokenId === 0;
    const imgUrl = hasSnap
      ? `https://stories.snapcaster.xyz/${tokenId}`
      : 'https://default.snapcaster.xyz';

    // If everything is loaded, we render the application.
    return (
      <div className="flex-column" style={{ fontFamily: 'futura' }}>
        <div className="d-flex justify-content-center flex-nowrap">
          <div
            className="card"
            style={{
              marginTop: 24,
              filter: 'drop-shadow( 0px 0px 4px rgba(0, 0, 0, .2))',
            }}
          >
            <div className="card-body">
              <div>
                {tokenId === undefined || tokenId === 0 ? (
                  <EmptyView
                    connectWallet={this._connectWallet}
                    noWallet={noWallet}
                    selectedAddress={selectedAddress}
                    mint={this.mint}
                  />
                ) : image ? (
                  <img src={image} width={320} />
                ) : (
                  <img src={imgUrl} width={320} />
                )}
              </div>
              <div>
                {disabled ? null : (
                  <InputButton
                    onImageChange={this.onImageChange}
                    hasImage={!!image}
                    upload={this.upload}
                    notify={this.notify}
                    hasSnap={hasSnap}
                  />
                )}
              </div>
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                <a
                  href="https://opensea.io/collection/snapcaster"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="20"
                    height="20"
                    src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"
                  />
                </a>
                <a
                  href={`https://etherscan.io/address/${contractAddress.Token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 4 }}
                >
                  <img width="20" height="20" src="/es.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center flex-nowrap"
          style={{ marginTop: 8 }}
        >
          {status}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    // We poll the user's balance, so we have to stop doing that when Dapp
    // gets unmounted
    this._stopPollingData();
  }

  async _nftMinted() {
    const { tokenId } = this.state;
    if (this._snap !== undefined && tokenId === undefined) {
      const { selectedAddress } = this.state;
      if (selectedAddress) {
        const tokenIdRaw = await this._snap.tokenForUser(selectedAddress);
        const tokenIdInt = Number(tokenIdRaw.toString());
        this.setState({ tokenId: tokenIdInt });
      }
    }
  }

  _connectWallet = async () => {
    // This method is run when the user clicks the Connect. It connects the
    // dapp to the user's wallet, and initializes it.

    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    const [selectedAddress] = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    // Once we have the address, we can initialize the application.

    // First we check the network
    if (!this._checkNetwork()) {
      return;
    }

    this._initialize(selectedAddress);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on('accountsChanged', ([newAddress]) => {
      this._stopPollingData();
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state
      if (newAddress === undefined) {
        return this._resetState();
      }

      this._initialize(newAddress);
    });

    // We reset the dapp state if the network is changed
    window.ethereum.on('chainChanged', ([networkId]) => {
      this._stopPollingData();
      this._resetState();
    });
  };

  _initialize(userAddress) {
    // This method initializes the dapp

    // We first store the user's address in the component's state
    this.setState({
      selectedAddress: userAddress,
    });

    // Then, we initialize ethers, fetch the token's data, and start polling
    // for the user's balance.

    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    this._initializeEthers();
    this._startPollingData();
  }

  async _initializeEthers() {
    // We first initialize ethers by creating a provider using window.ethereum
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    console.log({
      artifact: SnapCasterArtifact.abi,
      token: contractAddress.Token,
    });
    // Then, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    this._snap = new ethers.Contract(
      contractAddress.Token,
      SnapCasterArtifact.abi,
      this._provider.getSigner(0),
    );
  }

  // The next two methods are needed to start and stop polling data. While
  // the data being polled here is specific to this example, you can use this
  // pattern to read any data from your contracts.
  //
  // Note that if you don't need it to update in near real time, you probably
  // don't need to poll it. If that's the case, you can just fetch it when you
  // initialize the app, as we do with the token data.
  _startPollingData() {
    this._pollDataInterval = setInterval(() => {
      this._checkImage();
      this._nftMinted();
    }, 1000);
    this._checkImage();
    this._nftMinted();
    // We run it once immediately so we don't have to wait for it
  }

  _stopPollingData() {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  // This method checks if Metamask selected network is Localhost:8545
  _checkNetwork() {
    if (window.ethereum.networkVersion === MAINNET_NETWORK_ID) {
      this.setState({
        status: '',
      });
      return true;
    }

    this.setState({
      status: '⛓️ Please connect to Mainnet',
    });

    return false;
  }
}
