import React from 'react';

export const InputButton = ({
  onImageChange,
  upload,
  notify,
  hasSnap,
  hasImage,
}) => (
  <div className="mb-3">
    <form encType="multipart/form" style={{ width: 320 }}>
      <input
        className="form-control"
        type="file"
        onChange={onImageChange}
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        style={{ width: '100%', marginTop: 8 }}
        id="formFile"
      />
      <button
        onClick={upload}
        className="btn btn-outline-primary"
        style={{ width: '100%', marginTop: 8 }}
        type="submit"
        disabled={!hasImage}
      >
        📸 Publish
      </button>
      <button
        onClick={notify}
        className="btn btn-outline-primary"
        style={{ width: '100%', marginTop: 8 }}
        disabled={!hasSnap}
      >
        🔔 Notify
      </button>
    </form>
  </div>
);
