import React from 'react';
import { NoWalletDetected } from './NoWalletDetected';
import { ConnectWallet } from './ConnectWallet';

export const EmptyView = ({
  connectWallet,
  noWallet,
  selectedAddress,
  mint,
}) => (
  <div
    style={{
      overflow: 'hidden',
      position: 'relative',
      height: 320,
      width: 320,
    }}
  >
    <img
      src="https://default.snapcaster.xyz"
      width={320}
      style={{
        opacity: 0.2,
        position: 'absolute',
        left: 0,
        top: 0,
        width: 320,
        height: 320,
      }}
    />
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 320,
        }}
      >
        {selectedAddress ? (
          <button className="btn btn-warning btn-lg" onClick={mint}>
            Get SnapCaster NFT
          </button>
        ) : noWallet ? (
          <NoWalletDetected />
        ) : (
          <ConnectWallet connectWallet={connectWallet} />
        )}
      </div>
    </div>
  </div>
);
